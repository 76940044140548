<template>
  <div
    class="navbar-header d-xl-block d-none"
    :class="[`brand-${brand}`]"
  >
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <template v-if="brand === 'srp'">
            <b-img
              :src="srpBrand.appLogo"
              alt="StructrPro"
              width="200px"
            />
          </template>
          <template v-else>
            <span class="brand-logo">
              <b-img
                :src="seBrand.companyLogo1"
                alt="logo"
              />
              <b-img
                v-if="seBrand.companyLogo2 !==''"
                :src="seBrand.companyLogo2"
                alt="logo"
                class="ml-1"
              />
            </span>
            <h2 class="brand-text mb-0">
              {{ appName }}
            </h2>
          </template>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import BrandLogosMixin from '@/mixins/BrandLogosMixin.vue'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // Brand details
    const {
      brand, appName, appLogoImage, companyLogoImage1, companyLogoImage2,
    } = $themeConfig.app
    return {
      brand,
      appName,
      appLogoImage,
      companyLogoImage1,
      companyLogoImage2,
    }
  },
  mixins: [BrandLogosMixin],
}
</script>

<style>

</style>
